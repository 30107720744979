import { useEffect, useRef, useState } from 'react';
import SearchOff from '../components/SearchOff';
// import { Link } from 'react-router-dom';
// import { getMenuList, getPage } from '../Axios/pages';
// import * as DOMPurify from 'dompurify'
// import Loader from '../components/Loader';
// import TabGroupItem from '../components/TabGroupItem';
import TabsSlider from 'tabs-slider';
// import dayjs from 'dayjs';
import { getSynk } from '../Axios/searchoff';
import { formatUTC } from '../utils/utils';

function Home () {

  // const [topLoading, setTopLoading] = useState(true);
  // const [bottomLoading, setBottomLoading] = useState(true);
  const [dateSynk, setDateSynk] = useState(false);
  const [err, setErr] = useState(false);
  // const [, setGroupLoading] = useState(true);
  const tabs = useRef(null);

  // const [groups, setGroups] = useState([]);
  // const [pageContent, setPageContent] = useState({top: '', bottom: ''});

  if (tabs.current !== null) {
    new TabsSlider('.tabs');
  }

  useEffect(()=>{
    let active = false;
    window.scrollTo(0,0);
    // getPage(setTopLoading, 'power-top').then((response)=>{
    //     const [dataTop] = response['hydra:member'];
    
    //     getPage(setBottomLoading, 'power-bottom').then((response)=> {
    //         const [dataBottom] = response['hydra:member'];
    
    //         if(!active) {
    //             setPageContent({top: dataTop.content, bottom: dataBottom.content})
    //         }
    //     })
    // })

    // getMenuList(setGroupLoading, 'power-group').then((response)=>{
    //     const [groupList] = response['hydra:member'];
    //     if(!active) {
    //         setGroups(groupList.menuItems);
    //     }
    // })

    getSynk().then((response) => {
        const [dataSynk] = response['hydra:member'];
        if(!active) {
            setDateSynk(dataSynk.date);
        }
    }).catch((err) => {
      setErr("помилка синхронізації даних — " + err.message);
    })

    return () => {
        active = true;
    };
  }, [])

  const dateOptions = {
    timeZone: 'UTC',
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  
  const timeOptions = {
    timeZone: 'UTC',
    hour: 'numeric', 
    minute: 'numeric',
    second: 'numeric'
  };
  
  return (
    <div className="power-off">
        <div className="container">
        <div className="power-off__top">
        {/* {topLoading ? <Loader/> : <div data-aos="fade-up"
            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(pageContent.top, { ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] })}}>
        </div>} */}
        </div>
        <SearchOff/>
          {/* <div className="report">
            <div className="report__inner">
                <h2 className='power-off__title'>Якщо Вашого відключення немає у переліку — надішліть заявку про відсутність електропостачання.</h2>
                <Link className='transp-btn' to={'/send-off'}>
                    Повідомити
                </Link>
            </div>
            <div className="report__inner">
                <h2 className='power-off__title'>Окрім цього, Ви можете скерувати інформацію диспетчеру про невідповідність напруги.</h2>
                <Link className='transp-btn' to={'https://o-containe.loe.lviv.ua/low-quality-voltage?'} target='_blank'>
                    Повідомити
                </Link>
            </div>
          </div> */}
          <div className="date-synk">
            — Дата та час оновлення діючих вимкненнь: {err ? <span className='red'>{err}</span> : dateSynk && formatUTC(dateSynk, dateOptions, timeOptions)}
          </div>
          {/* <div className="attention" data-aos="fade-up">
            {bottomLoading ? <Loader/> : <div
                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(pageContent.bottom, { ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] })}}>
            </div>}
         </div> */}
         {/* <div className="power-off__group"> 
            <Link className='power-a' to={'/shedule-off'}>Дізнатися групу відключень</Link> 
           {groups.length > 0 && 
            <div className="tabs" ref={tabs}>
            <div className="tabs__bar-wrap">
                <div className="tabs__bar">
                    {groups.map(group=>{
                        return <div key={group.id} className="tabs__controls md-ripple">{group.name}</div>
                    })}
                </div> 
            </div>
                <div className="tabs__content">
                    {groups.map(group=>{
                        return  <TabGroupItem group={group} key={group["@id"]} />
                    })}
                </div>
            </div>}
         </div> */}
        </div>
    </div>
  );
}

export default Home