import { useEffect, useState } from "react";
import GroupSerach from "../components/GroupSerach";
import { getGraficOptions, getSynk } from "../Axios/searchoff";
import { formatUTC } from "../utils/utils";
import { Link } from 'react-router-dom';

const GroupPage = () => {

    const [graficToday, setGraficToday] = useState(false);
    const [graficTomorrow, setGraficTomorrow] = useState(false);
    const [dateSynk, setDateSynk] = useState(false);
    const [err, setErr] = useState(false);

    useEffect(()=>{
        window.scrollTo(0,0);
      }, [])

    useEffect(()=>{
        let active = false;
        getGraficOptions( 'pw_gpv_image_today').then((response)=>{
            const [today]= response['hydra:member'];
            if(!active) {
                setGraficToday(today);
            }
        }).catch((err) => {
            console.log(err);
            setGraficToday(false);
        })
    
        getGraficOptions( 'pw_gpv_image_tomorrow').then((response)=>{
            const [tomorrow]= response['hydra:member'];
            if(!active) {
                setGraficTomorrow(tomorrow);
            }
        }).catch((err) => {
            console.log(err);
            setGraficTomorrow(false);
        })

        getSynk().then((response) => {
            const [dataSynk] = response['hydra:member'];
            if(!active) {
                setDateSynk(dataSynk.date);
            }
        }).catch((err) => {
          setErr("— помилка синхронізації даних — " + err.message);
        })

        return () => {
            active = true;
        };
    }, [])  

    const dateOptions = {
        timeZone: 'UTC',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };
      
      const timeOptions = {
        timeZone: 'UTC',
        hour: 'numeric', 
        minute: 'numeric',
      };

    return (
        <section className="power-off">
            <div className="container">
            <div className="content" data-aos="fade-up">
                <h2 className="power-off__title">
                    Актуалізовану інформацію щодо годин включення/відключення електроенергії 
                    станом на {err ? <span className='red'>{err}</span> : dateSynk && formatUTC(dateSynk, dateOptions, timeOptions) + ' наведено нижче:'}</h2>
                <div className="group-images">
                    {(graficToday && graficToday?.value?.length > 1) &&
                        <Link target='_blank' to={process.env.REACT_APP_SERVER_SEARCH_URL + graficToday.value}>
                            <img height={265} width={1038} src={process.env.REACT_APP_SERVER_SEARCH_URL + graficToday.value} alt="grafic" />
                        </Link>
                    }
                    {(graficTomorrow && graficTomorrow?.value?.length > 1) &&
                        <Link target='_blank' to={process.env.REACT_APP_SERVER_SEARCH_URL + graficTomorrow.value}>
                            <img height={265} width={1038} src={process.env.REACT_APP_SERVER_SEARCH_URL + graficTomorrow.value} alt="grafic" />
                        </Link>
                    }
                </div>
                <GroupSerach />
            </div>
            </div>
        </section> 
    )
}

export default GroupPage