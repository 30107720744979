import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select';
// import * as dayjs from 'dayjs';
import { getAddressQueues, getCityList, getStreetList } from '../Axios/searchoff';
import Table from 'react-bootstrap/Table';
import { ToastContainer } from "react-toastify";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PaginationLoe from './PaginationLoe';
import { defaultOptions, selectOptions } from '../utils/search';

const GroupSerach = () => {

  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedStreet, setSelectedStreet] = useState(null);

  const [buildingValue, setBuildingValue] = useState('');

  const [cityOptions, setCityOptions] = useState([]);
  const [streetOptions, setStreetOptions] = useState([]);

  const [podiysList, setPodiysList] = useState([]);
  const [border, setBorder] = useState({otg: '#C4CBD2', city: '#C4CBD2'});
  const [color, setColor] = useState({otg:'hsl(0, 0%, 50%)', city: 'hsl(0, 0%, 50%)'});

  const formRef = useRef(null);
  const citySelect = useRef(null);
  const streetSelect = useRef(null);

  const tableOff = useRef(null);

  const [page, setPage] = useState(1);
  const [totalItems, setTotal] = useState(1);
  
  const [noOff, setNoOff] = useState('');

  useEffect(()=>{
    selectOptions(getCityList, setCityOptions);
  },[]);

  useEffect(()=>{
    if(selectedCity !== null){
      selectOptions(getStreetList, setStreetOptions, selectedCity.value);
    }
  },[selectedCity])

  function alertClolors(type) {
    setBorder({...border, [type]: '#e74c3c'});
    setColor({...color, [type]:'#e74c3c'});
    setTimeout(() => {
      setBorder({...border, [type]:'#C4CBD2'});
      setColor({...color, [type]:'hsl(0, 0%, 50%)'});
    }, 4000);
  }
  
  const handleChangePage = (currentPage)=> {
    formRef.current.scrollIntoView({behavior: 'smooth'})
    setPage(currentPage);
    getAddressQueues(selectedCity?.value || '', selectedStreet?.value || '', buildingValue|| '', currentPage).then(response=>{
      const data = response['hydra:member'];
      setTotal(response['hydra:totalItems'])
      setPodiysList(data);
    })
  }

  function handleSubmit(e) {
    e.preventDefault();
    setPage(1);

    // відправка запиту в разі якщо дані заповнено
    if (selectedCity !== null) {
      getAddressQueues(selectedCity?.value || '', selectedStreet?.value || '', buildingValue || '', 1).then(response=>{
        const data = response['hydra:member'];
        setTotal(response['hydra:totalItems']);
        setPodiysList(data);
        if(data.length === 0) {
          toast.error('Черги не знайдено 💡', {
            position: toast.POSITION.TOP_LEFT
         });
         setNoOff('За обраним Вами населеним пунктом/вулицею/будинком черги не знайдено.');
         clearData();
        }
      }).catch((err)=> {
        toast.error(`Помилка обробки даних ${err.message}`, {
            position: toast.POSITION.TOP_LEFT
        });
        clearData();
      })
    }
    else {
      toast.error(`Заповніть поле «Населений пункт»`, {
        position: toast.POSITION.TOP_LEFT,
        autoClose: 3000,
      });
      alertClolors('city');
    }
  }
  
  function clearData(){
    citySelect.current.clearValue(); 
    streetSelect.current.clearValue();
    setBuildingValue('');
    if(selectedCity !== null){
      selectOptions(getCityList, setCityOptions);
    }
  }

  return (
      <div className="search-off">
        <h2 className='power-off__title'>Скористайтесь формою для пошуку потрібної адреси серед всіх черг погодинних відключень:</h2>
        <form action="submit" onSubmit={handleSubmit} ref={formRef} data-aos="fade-up">
         <label htmlFor="city">Населений пункт</label>
          <Select
            cacheOptions
            ref={citySelect} 
            id={'city'}
            placeholder={'Введіть назву населеного пункту'} 
            onChange={setSelectedCity} 
            defaultOptions={defaultOptions}
            options={cityOptions}
            noOptionsMessage={() => 'Адреси не знайдено'} 
            loadingMessage={() => 'Завантаження...'}
            onSelectResetsInput = {false}
            onMenuOpen={() => {
              citySelect.current.clearValue(); 
              streetSelect.current.clearValue();
            }}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                border: `1px solid ${border.city}`,
                width: '400px',
                display: 'flex',
                height: '58px',
                borderRadius: '4px',
                background: 'white',
                paddingLeft: '10px',
              }),
              placeholder: (baseStyles) => ({
                ...baseStyles,
                color: color.city
              })
            }}
            onFocus={()=>{setBorder({...border, city: '#C4CBD2'}); setColor({...color, city: 'hsl(0, 0%, 50%)'})}}
            />
         <label htmlFor="street">Вулиця</label>
         <Select 
            cacheOptions
            ref={streetSelect}
            id={'street'}
            placeholder={(selectedCity === null) ? 'Заповніть поле "Населений пункт"' : "Введіть назву вулиці"} 
            onChange={setSelectedStreet} 
            options={streetOptions}
            noOptionsMessage={() => 'Адреси не знайдено'} 
            loadingMessage={() => 'Завантаження...'}
            isDisabled={selectedCity === null}
            onMenuOpen={()=>streetSelect.current.clearValue()}
          />
          <label htmlFor="street">Будинок</label>
          <input 
              disabled = {selectedCity === null}
              type="text" 
              className='input-search'
              value={buildingValue} 
              onChange={(e) => setBuildingValue(e.target.value)} placeholder={(selectedCity === null) ? 'Заповніть поле "Населений пункт"' : 'Введіть номер будинку'}
          />       
         <button className='power-btn'>Знайти</button>
         <ToastContainer/> 
       </form>
        {podiysList.length > 0 ?
        <>
          <div className="arrow-inner">	
            <div className="scroll-left" onClick={()=>{tableOff.current.scrollLeft -= 120;}}>&#8592;</div> 
            <div className="scroll-right" onClick={()=>{tableOff.current.scrollLeft += 120;}}>&#8594;</div>
          </div>
        <div className="search-off__table" ref={tableOff}>
          <Table striped bordered hover>
          <thead>
            <tr>
              <th>Черга</th>
              <th>Населений пункт</th>
              <th>Назва вулиці</th>
              <th>Номери будинків</th>
            </tr>
          </thead>
          <tbody>
            {podiysList.map(podiy=>{
              return <tr key={podiy['@id']}>
                        <td style={{textAlign: 'center'}}>{(podiy.chergGpv !== 'Не входить' && podiy.chergGpv !== "") ? `${podiy.chergGpv} Черга` : podiy.chergGpv}</td>
                        <td style={{textAlign: 'center'}}>{podiy.city.name}</td>
                        <td>{podiy.street.name}</td>
                        <td style={{maxWidth: '350px', wordWrap: 'break-word'}}>{podiy.buildingNames}</td>
                    </tr>
            })}
          </tbody>
          </Table>
          </div>
               <PaginationLoe
               changePage={handleChangePage}
               currentPage={page}
               total={totalItems}
           /> 
        </> 
        : <p className="off-msg"><strong>{noOff}</strong></p>
      }
      </div>
  )
}

export default GroupSerach